
import { defineComponent, ref } from "vue"

import { touchEventOffset } from "@/utils"

let skipValueUpdates = 0

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
    },
    postfix: {
      type: String,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    updateSlack: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const positionOverride = ref(false)
    const override = ref(0)
    const animated = ref(false)
    const touching = ref(false)
    const moving = ref(false)
    const lastEmitted = ref(false)

    return {
      positionOverride,
      animated,
      touching,
      moving,
      lastEmitted,
      override,
    }
  },
  computed: {
    marginAmt() {
      let amt = 0
      if (this.prefix) amt++
      if (this.postfix) amt++
      return amt
    },
    position(): number {
      return this.positionOverride ? this.override : this.value
    },
  },
  watch: {
    value() {
      if (skipValueUpdates > 1) skipValueUpdates--
      else if (skipValueUpdates === 1) {
        skipValueUpdates = 0
        this.positionOverride = false
      }
    },
  },
  methods: {
    // TODO check if duplicates still happen
    emit(_: string, value: any) {
      if (this.lastEmitted !== value) {
        this.lastEmitted = value
        this.$emit("input", value)
      }
    },
    contextmenu(event: any) {
      event.preventDefault()
    },
    mouseDown(event: any) {
      // TODO fix mouse sliding when out of bounds
      this.touching = true

      const position = event.offsetX / event.target.scrollWidth
      this.positionOverride = true
      this.override = position

      event.target.onmousemove = (evt: any) => {
        const pos = evt.offsetX / evt.target.scrollWidth
        this.positionOverride = true
        this.override = pos
        if (this.immediate) this.emit("input", pos)
      }

      event.target.onmouseup = (evt: any) => {
        const pos = evt.offsetX / evt.target.scrollWidth
        event.target.onmousemove = null
        if (this.updateSlack > 0) skipValueUpdates = this.updateSlack
        else this.positionOverride = false
        this.touching = false

        this.emit("input", pos)
      }
    },
    touchStart(event: any) {
      this.touching = true
      this.moving = false

      event.target.ontouchmove = (evt: any) => {
        this.moving = true
        const [offX] = touchEventOffset(evt.changedTouches[0], event.target)
        const pos = Math.min(Math.max(offX / evt.target.scrollWidth, this.min), this.max)
        this.positionOverride = true
        this.override = pos
        if (this.immediate) this.emit("input", pos)
      }

      event.target.ontouchend = (evt: any) => {
        this.moving = false
        const [offX] = touchEventOffset(evt.changedTouches[0], event.target)
        const pos = Math.min(Math.max(offX / evt.target.scrollWidth, this.min), this.max)
        event.target.ontouchmove = null
        this.touching = false
        if (this.updateSlack > 0) skipValueUpdates = this.updateSlack
        else this.positionOverride = false

        this.emit("input", pos)
      }
    },
  },
})
