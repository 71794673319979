
import { defineComponent, onMounted, ref } from "vue"

import { ls } from "@/utils"

export default defineComponent({
  name: "Home",
  setup() {
    const params = ref([[""]])

    onMounted(() => {
      const { searchParams } = new URL(window.location.href)
      params.value = [...searchParams.entries()]
    })

    const setTidalAccessToken = () => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const access_token = prompt("enter access_token")

      // eslint-disable-next-line @typescript-eslint/camelcase
      if (access_token) ls("tidal-login", { access_token })
    }

    return { params, setTidalAccessToken }
  },
})
