
import { defineComponent, ref, onMounted, onBeforeUnmount, toRefs, watch } from "vue"
import { MDCSwitch } from "@material/switch"

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
    },
  },
  setup(props) {
    const { label } = toRefs(props)
    const mdcSwitchRef = ref<HTMLElement | null>(null)

    let mdcSwitch: undefined | MDCSwitch

    const init = () => {
      if (!mdcSwitchRef.value) throw new Error("template ref not available at mount")
      mdcSwitch = new MDCSwitch(mdcSwitchRef.value)
    }

    const destroy = () => {
      if (mdcSwitch) mdcSwitch.destroy()
    }

    const reInit = () => {
      destroy()
      init()
    }

    onMounted(init)
    onBeforeUnmount(destroy)
    watch(label, reInit)

    return { mdcSwitchRef }
  },
})
