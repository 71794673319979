
import { defineComponent, ref, onMounted, computed } from "vue"
import { useStore } from "./store/store"

import SvgSprite from "@/components/functional/SvgSprite"
import topNav from "@/components/TopNav.vue"
import bottomNav from "@/components/BottomNav.vue"
import bottomPlayer from "@/components/BottomPlayer.vue"
import globalStyles from "@/components/functional/GlobalStyles"
import audioPlayer from "@/components/functional/AudioPlayer"

import player from "@/player"
import { useRoute, useRouter } from "vue-router"

// @ts-expect-error
window.player = player

export default defineComponent({
  components: { topNav, bottomNav, bottomPlayer, audioPlayer, globalStyles, SvgSprite },
  setup() {
    const { state } = useStore()
    const scrolled = ref(false)
    const main = ref<HTMLElement | null>(null)

    if (process.env.NODE_ENV === "development") {
      // for debugging
      // @ts-expect-error
      window.route = useRoute()
      // @ts-expect-error
      window.router = useRouter()
      // @ts-expect-error
      window.store = useStore()
    }

    onMounted(() => {
      if (!main.value) throw new Error("template ref not available at mount")
      main.value.onscroll = (e: any) => {
        if (e.target.scrollTop > 0) scrolled.value = true
        else scrolled.value = false
      }
    })

    const noTrack = computed(() => {
      const allEmpty = (arr: string[]) => !arr.filter(s => s).length

      const { artist, id, stream, title } = state.currentTrack
      return allEmpty([artist, id, stream, title])
    })

    return {
      scrolled,
      main,
      noTrack,
      currentTrack: computed(() => state.currentTrack),
    }
  },
})
