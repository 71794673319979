
import { defineComponent, ref, computed, toRefs, PropType, watch } from "vue"
import CloudrImage from "@/components/functional/CloudrImage"

import { toCloudrID, srcset } from "@/utils"
import { Track } from "@/player/musicSource"
import { useStore } from "@/store/store"

export default defineComponent({
  components: { CloudrImage },
  props: {
    trackInfo: {
      type: Object as PropType<Track>,
      required: true,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const height = ref(0)
    const main = ref<HTMLElement | null>(null)
    const { trackInfo, hide } = toRefs(props)
    const { state } = useStore()

    const isPlaying = computed(() => {
      const { platform, id } = trackInfo.value
      return toCloudrID(platform, id) === state.currentTrack.id
    })

    watch(hide, () => {
      if (!main.value) throw new Error("template ref not available at mount")
      if (hide.value) height.value = main.value.scrollHeight
      else height.value = 0
    })

    return {
      height,
      isPlaying,
      main,
      imgSrc: computed(() => srcset(trackInfo.value.artwork, 40)),
    }
  },
})
