
import { defineComponent, ref, toRefs, computed } from "vue"
import MwcSwitch from "@/components/mwc/Switch.vue"
import { pref } from "@/strings"
import { isObject } from "@/utils"
import Slider from "@/components/Slider.vue"
import DynamicHeightTransition from "@/components/functional/DynamicHeightTransition"

export default defineComponent({
  name: "preference",
  components: { MwcSwitch, Slider, DynamicHeightTransition },
  props: {
    preference: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Boolean, Number],
      required: true,
    },
  },
  setup(props) {
    const collapsed = ref(true)
    const { preference, modelValue } = toRefs(props)

    const name = computed(() => {
      const strings = pref[preference.value]

      if (strings) return strings.name
      return preference.value
    })

    const desc = computed(() => {
      const strings = pref[preference.value]

      if (!strings) return ""

      if (typeof strings.desc === "string") return strings.desc
      if (isObject(strings.desc)) return strings.desc?.[String(modelValue.value)] || ""

      return ""
    })

    const type = computed(() => {
      return typeof modelValue.value
    })

    const valueDisplay = computed(() => {
      const strings = pref[preference.value]
      return strings.translateValue?.(modelValue.value) || modelValue.value
    })

    const isNumber = computed(() => {
      return type.value === "number"
    })

    return {
      name,
      desc,
      type,
      valueDisplay,
      isNumber,
      collapsed,
      preferenceClick() {
        if (type.value === "number") collapsed.value = !collapsed.value
      },
    }
  },
})
