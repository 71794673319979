
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    scale: {
      type: Number,
      default: 1,
    },
  },
})
