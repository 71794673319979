
import { defineComponent, computed } from "vue"
import CloudrImage from "@/components/functional/CloudrImage"

import { formatTime, srcset } from "@/utils"

import Slider from "@/components/Slider.vue"
import { useStore } from "@/store/store"

export default defineComponent({
  components: { Slider, CloudrImage },
  setup() {
    const { state, commit, dispatch } = useStore()

    const playing = computed(() => state.player.playing)
    const playPause = () => commit("setPlayer", ["playing", !playing.value])
    const sliderVal = (pos: number) => commit("setPlayer", ["setPosition", pos])

    return {
      formatTime,
      playPause,
      sliderVal,
      playing,
      prevTrack: () => dispatch("prevTrack"),
      nextTrack: () => dispatch("nextTrack"),
      progress: computed(() => state.player.progress),
      duration: computed(() => state.player.duration),
      title: computed(() => state.currentTrack.title),
      artist: computed(() => state.currentTrack.artist),
      imgSrc: computed(() => srcset(state.currentTrack.artwork, 55)),
    }
  },
})
