
import { defineComponent, ref, PropType, computed, toRefs } from "vue"
import CloudrImage from "@/components/functional/CloudrImage"

import { srcset } from "@/utils"
import { MediaImage } from "@/player/musicSource"

export default defineComponent({
  components: { CloudrImage },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    avatar: {
      type: Array as PropType<MediaImage[]>,
      required: true,
    },
  },
  setup(props) {
    const descriptionExpanded = ref(false)
    const expanded = ref(false)
    const { avatar } = toRefs(props)

    const outsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (target.closest(".avatar")) return
      expanded.value = false

      window.removeEventListener("pointerdown", outsideClick)
    }

    const expand = () => {
      expanded.value = !expanded.value

      if (expanded.value) window.addEventListener("pointerdown", outsideClick, false)
      else window.removeEventListener("pointerdown", outsideClick)
    }

    return {
      descriptionExpanded,
      expanded,
      expand,
      imgSrc: computed(() => srcset(avatar.value, 500)),
    }
  },
})
