
import { defineComponent } from "vue"
import Preference from "@/components/Preference.vue"
import { isObject } from "@/utils"
import { SettingsValue } from "@/types"
import { useStore } from "@/store/store"

export default defineComponent({
  name: "preferences",
  components: { Preference },
  setup() {
    const { state, dispatch } = useStore()

    const handleChange = (value: SettingsValue, pref: string, sub?: string) => {
      dispatch("pref", [value, pref, sub])
    }

    return {
      isObject,
      handleChange,
      preferences: state.preferences,
    }
  },
})
