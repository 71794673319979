
import player from "@/player"
import Spinner from "@/components/Spinner.vue"
import { defineComponent } from "vue"
import { useRouter, useRoute } from "vue-router"

const soundcloud = player("soundcloud")

export default defineComponent({
  components: { Spinner },
  name: "soundcloud-resolve",
  setup() {
    const router = useRouter()
    const route = useRoute()

    const resolve = async () => {
      try {
        const resolved = await soundcloud.resolve?.(route.path)
        if (!resolved) return router.replace("/")
        router.replace(resolved)
      } catch (err) {
        console.log("resolve error", err)
        router.replace("/")
      }
    }

    resolve()
  },
})
