
import { defineComponent } from "vue"

export default defineComponent({
  name: "top-nav",
  props: {
    scrolled: {
      type: Boolean,
      default: false,
    },
  },
})
