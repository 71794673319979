
import { defineComponent, ref, computed } from "vue"
import { useRoute, RouteParams, onBeforeRouteUpdate } from "vue-router"

import artwork from "@/components/ArtworkInfo.vue"
import TrackListItem from "@/components/TrackListItem.vue"
import InfiniteScroll from "@/components/functional/InfiniteScroll"
import Spinner from "@/components/Spinner.vue"

import { toCloudrID, PlatformAccessor } from "@/utils"

import player from "@/player"
import { MediaImage, Track, MusicSource } from "@/player/musicSource"
import { useStore } from "@/store/store"

declare global {
  interface Window {
    playlist: any
  }
}

export default defineComponent({
  name: "playlist",
  components: { artwork, TrackListItem, InfiniteScroll, Spinner },
  setup() {
    const route = useRoute()
    type PlaylistRouteParams = RouteParams & {
      platform: PlatformAccessor
      id: string
    }
    const params = route.params as PlaylistRouteParams

    const { state, commit, dispatch } = useStore()

    const playlistInfo = ref({
      artwork: [] as MediaImage[],
      id: "",
      title: "loading",
      user: {
        username: "loading",
        id: "",
      },
    })
    const playlistTracks = ref<Track[]>([])
    const playlistNext = ref()

    const likes = computed(() => route.name === "Likes")

    const loadPlaylistInfo = async (plat: MusicSource, id: string) => {
      if (likes.value && plat.user) {
        const user = await plat.user(id)

        playlistInfo.value = {
          id: user.id,
          artwork: user.avatar,
          title: `${user.username}'s likes`,
          user,
        }
      } else playlistInfo.value = await plat.playlistInfo(id)
    }

    const loadPlaylistTracks = async (plat: MusicSource, id: string) => {
      let tracks

      if (likes.value && plat.likes) tracks = await plat.likes(id)
      else tracks = await plat.playlistTracks(id)

      playlistTracks.value = tracks.tracks
      playlistNext.value = tracks.next
    }

    const loadPlaylist = async (_params: PlaylistRouteParams) => {
      const { platform, id } = _params
      const plat = player(platform)

      loadPlaylistInfo(plat, id)
      await loadPlaylistTracks(plat, id)

      const main = document.querySelector("main")
      if (main) main.scrollTop = 0
    }

    onBeforeRouteUpdate(async to => await loadPlaylist(to.params as PlaylistRouteParams))

    const loadNext = async () => {
      if (!playlistNext.value) return

      const { tracks, next } = await playlistNext.value()

      playlistTracks.value = [...playlistTracks.value, ...tracks]
      playlistNext.value = next

      const { platform, id } = params
      if (state.playingList === toCloudrID(platform, id))
        commit("setQueue", [...state.queue, ...tracks])
    }

    const playTrack = async (track: Track, index: number) => {
      const { platform, id } = params

      dispatch("playTrack", toCloudrID(track.platform, track.id))
      commit("setQueuePrev", playlistTracks.value.slice(0, index))
      commit("setQueue", playlistTracks.value.slice(index))
      commit("setPlayingList", toCloudrID(platform, id, "playlist"))
    }

    loadPlaylist(params)

    return {
      toCloudrID,
      playlistInfo,
      playlistTracks,
      playlistNext,
      loadNext,
      playTrack,
    }
  },
})
